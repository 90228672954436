import React, { useState, useEffect } from "react";
import { Card, Box, Typography, Avatar, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import SideBar from "./SideBar";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ArchivedCourses = () => {
  const [archivedCourses, setArchivedCourses] = useState([]);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    const fetchArchivedCourses = async () => {
      try {
        const response = await axiosInstance.post("/data-courses");
        const filteredCourses = response.data.data.filter(course => course.archive === true);
        setArchivedCourses(filteredCourses);
      } catch (error) {
        console.error("Error fetching archived courses:", error);
      }
    };

    fetchArchivedCourses();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const handleMenuOpen = (event, courseId) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedCourseId(courseId);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
    setAnchorEl(null);
    setSelectedCourseId(null);
  };

  const handleArchiveCourse = async (courseId) => {
    try {
      await axiosInstance.put(`/courses/${courseId}`, { archive: false });
    } catch (error) {
      console.error("Error unarchiving course:", error);
    }
  };
  
  
  
  
  return (
    <div>
      <SideBar />
      <div className="main-content">
        <div className="card-grid">
          <Grid container spacing={3} sx={{ padding: "20px" }}>
            {archivedCourses.length > 0 ? (
              archivedCourses.map((course, index) => (
                <><Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", overflow: "hidden" }}>
                    <Box
                      sx={{
                        backgroundImage: `url(${course.courseThumbnail || "default-image-url.jpg"})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "100px",
                        padding: "16px",
                        position: "relative",
                      }}
                    >
                      <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.4)" }} />
                      <Typography variant="h6" sx={{ fontWeight: "bold", lineHeight: 1.2, color: "white", position: "relative", zIndex: 1 }}>
                        {course.courseName}
                      </Typography>
                      <Typography variant="body2" sx={{ marginTop: "8px", color: "white", position: "relative", zIndex: 1 }}>
                        {course.type || "General Class"}
                      </Typography>
                      <Typography variant="body2" sx={{ marginTop: "4px", color: "white", position: "relative", zIndex: 1 }}>
                        {course.teacher?.userName || "Unknown Teacher"}
                      </Typography>
                    </Box>

                    <Box sx={{ flexGrow: 1, backgroundColor: "#ffffff", height: "50px", position: "relative" }}>
                      <Avatar sx={{
                        position: "absolute",
                        right: "16px",
                        top: "-24px",
                        bgcolor: "#6c757d",
                        color: "#ffffff",
                        fontWeight: "bold",
                        width: 60,
                        height: 60,
                        fontSize: "40px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                      }}>
                        {course.courseName.charAt(0)}
                      </Avatar>
                    </Box>

                    <Grid container justifyContent="flex-end" alignItems="center" sx={{ padding: "10px", backgroundColor: "#ffffff", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
                      <Box>
                        <IconButton>
                          <CalendarTodayIcon sx={{ fontSize: 20, color: "#000" }} />
                        </IconButton>
                        <IconButton>
                          <FolderOpenIcon sx={{ fontSize: 20, color: "#000" }} />
                        </IconButton>
                        <IconButton onClick={(event) => handleMenuOpen(event, course._id)}>
                          <MoreVertIcon sx={{ fontSize: 20, color: "#000" }} />
                        </IconButton>

                      </Box>
                    </Grid>
                  </Card>
                </Grid><Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose}>
                    <MenuItem onClick={() => handleArchiveCourse(course._id)}>Unarchive Course</MenuItem>
                  </Menu></>
              ))
            ) : (
              <Typography variant="h6" align="center" sx={{ width: "100%" }}>
                No archived courses found
              </Typography>
            )}
          </Grid>
        </div>
      </div>



    </div>
  );
};

export default ArchivedCourses;
