import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import Sidebar from "./SideBar";

const FeedbackDetails = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });


    useEffect(() => {
        const fetchFeedbacks = async () => {
            try {
                const response = await axiosInstance.get("/feedback/all");
                setFeedbacks(response.data);
            } catch (error) {
                console.error("Error fetching feedback:", error);
            }
        };

        fetchFeedbacks();
    }, []);

    console.log(feedbacks)

    return (
          <div>
              <div
                className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header"
                id="appContent"
              >
                <div className="app-main">
                  <Sidebar />
                  <div className="col mt-4">
                  <div className="row">
                  <main id="main" class="main">

<section class="section dashboard">
  <div class="row">
                                <div className="col-md-12">
                  
                  <div className="card recent-sales overflow-auto w-100">
            <div className="card-body">
                <h5 className="card-title">User Feedback <span>| Reviews</span></h5>

                <table className="table table-borderless datatable w-100" style={{ width: "100%", borderCollapse: "collapse" }}
            >
                <thead>
                        <tr>
                            <th scope="col">#</th>
                          
                           
                            <th scope="col">Comments</th>
                            <th scope="col">Rating</th>
                            <th scope="col">Published Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {feedbacks.map((feedback, index) => (
                            <tr key={feedback._id}>
                                <td scope="row"><a href="#">#{index + 1}</a></td>
                          
                             
                                <td><a href="#" className="text-primary">{feedback.comment}</a></td>
                                <td>{feedback.rating} ★</td>
                                <td>{new Date(feedback.createdAt).toLocaleDateString()}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        </div>
        </div>
        </section>
        </main>
        </div>
        </div>
        </div>
        </div>
        </div>
    );
};

export default FeedbackDetails;
