import React, { useState, useEffect } from "react";
import SideBar from './SideBar';
import axios from "axios";
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';


const ProfileSettings = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    bio: '',
    url: '',
    location: '',
    userName: '',
    email: '',
    password: '',
    role: 'Student',
    profilePic: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    },
    description: '',
    links: [''],
    identityVerifications: [''],
    phoneNumber: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

   const [userData, setUserData] = useState(null);
   const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
 
   useEffect(() => {
     const userDataFromStorage = localStorage.getItem("user");
     if (userDataFromStorage) {
       try {
         const parsedData = JSON.parse(userDataFromStorage);
         setUserData(parsedData);
       } catch (error) {
         console.error("Failed to parse user data:", error);
       }
     }
   }, []);

   const userId = userData ? userData._id : null;
 

  // Fetch user details on component mount
  useEffect(() => {
    axiosInstance.get(`/auth/details/${userId}`)
      .then(response => {
        setFormData(response.data); // Populate form with current user data
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching user data:', err);
        setError('Failed to load user details.');
        setLoading(false);
      });
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('address')) {
      const field = name.split('.')[1];
      setFormData(prevState => ({
        ...prevState,
        address: {
          ...prevState.address,
          [field]: value
        }
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(`/auth/details/${userId}`, formData);
      console.log('Profile updated:', response.data);
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile.');
    }
  };

  const handleUsernameUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(`/auth/username/${userId}`, { userName: formData.userName });
      console.log('Updated username:', response.data); // Debugging
      alert('Username updated successfully!');
    } catch (error) {
      console.error('Error updating username:', error);
      alert('Failed to update username.');
    }
  };

  const handleChangeUserName = (e) => {
    setFormData({ ...formData, userName: e.target.value });
  };


  const handleDeleteAccount = async () => {
    if (!window.confirm('Are you sure you want to delete your account? This action cannot be undone!')) return;
    try {
      await axios.delete(`/details/${userId}`);
      alert('Account deleted successfully!');
      window.location.href = '/'; // Redirect after deletion
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('Failed to delete account.');
    }
  };


  const [message, setMessage] = useState('');

  const handlePasswordChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (formData.newPassword !== formData.confirmPassword) {
      setMessage('New passwords do not match.');
      return;
    }

    try {
      const response = await axiosInstance.put(`/auth/change-password/${userId}`, {
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword
      });

      setMessage(response.data.message);
      setFormData({ oldPassword: '', newPassword: '', confirmPassword: '' }); // Reset form
    } catch (error) {
      setMessage(error.response?.data?.message || 'Failed to update password.');
    }
  }

  const [location, setLocation] = useState(null);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const res = await fetch("https://ipinfo.io/json?token=3b1182fc702f79"); // Replace YOUR_API_KEY with your actual API key from ipinfo.io
        const data = await res.json();
        setLocation(data);
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    fetchLocation();
  }, []);



  return (
    <div>
  
    <SideBar />
    <div className="main-content">
      <div className="card-grid">
    <div className="container">


      <div className="row gutters-sm">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h6>Profile Information</h6>
              <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="fullName">User Email </label>
        <input type="email" className="form-control" id="fullName" name="Email" value={formData.email} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number</label>
        <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea className="form-control" id="description" name="description" value={formData.description} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label>Address</label>
        <input type="text" className="form-control mb-2" name="address.street" placeholder="Street" value={formData?.address?.street || ''} onChange={handleChange} />
<input type="text" className="form-control mb-2" name="address.city" placeholder="City" value={formData?.address?.city || ''} onChange={handleChange} />
<input type="text" className="form-control mb-2" name="address.state" placeholder="State" value={formData?.address?.state || ''} onChange={handleChange} />
<input type="text" className="form-control mb-2" name="address.zip" placeholder="ZIP Code" value={formData?.address?.zip || ''} onChange={handleChange} />
<input type="text" className="form-control" name="address.country" placeholder="Country" value={formData?.address?.country || ''} onChange={handleChange} />
   </div>

      <div className="form-group">
        <label htmlFor="links">Links</label>
        {(formData.links || []).map((link, index) => (
  <input
    key={index}
    type="text"
    className="form-control mb-2"
    name={`links.${index}`}
    value={link}
    onChange={handleChange}
    placeholder="Add a link"
  />
))}

      </div>

      <div className="form-group">
        <label htmlFor="identityVerifications">Identity Verifications</label>
        {(formData.identityVerifications || []).map((verification, index) => (
          <input key={index} type="text" className="form-control mb-2" name={`identityVerifications.${index}`} value={verification} onChange={handleChange} placeholder="Add verification" />
        ))}
      </div>

      <button type="submit" className="btn btn-primary">Update Profile</button>
    </form>
            </div>
          </div>
        </div>

  
          <div className="card">
            <div className="card-body">
              <h6>Account Settings</h6>
              <form onSubmit={handleUsernameUpdate}>
      <div className="form-group">
        <label htmlFor="username">Username</label>
        <input 
  type="text" 
  className="form-control" 
  id="userName" 
  name="userName" 
  value={formData.userName} 
  onChange={handleChangeUserName} 
/>
        <small className="form-text text-muted">After changing your username, your old username becomes available for anyone else to claim.</small>
      </div>
      <button type="submit" className="btn btn-primary">Update Username</button>

      <hr />

      <div className="form-group">
        <label className="d-block text-danger">Delete Account</label>
        <p className="text-muted font-size-sm">Once you delete your account, there is no going back. Please be certain.</p>
      </div>
      <button className="btn btn-danger" type="button" onClick={handleDeleteAccount}>Delete Account</button>
    </form>

              <hr />

              <h6>Security Settings</h6>
              <form onSubmit={handlePasswordSubmit}>
      <div className="form-group">
        <label className="d-block">Change Password</label>
        <input 
          type="password" 
          className="form-control" 
          placeholder="Enter your old password" 
          name="oldPassword" 
          value={formData.oldPassword} 
          onChange={handlePasswordChange} 
          required 
        />
        <input 
          type="password" 
          className="form-control mt-1" 
          placeholder="New password" 
          name="newPassword" 
          value={formData.newPassword} 
          onChange={handlePasswordChange} 
          required 
        />
        <input 
          type="password" 
          className="form-control mt-1" 
          placeholder="Confirm new password" 
          name="confirmPassword" 
          value={formData.confirmPassword} 
          onChange={handlePasswordChange} 
          required 
        />
      </div>
      <button type="submit" className="btn btn-primary mt-2">Update Password</button>
      {message && <p className="mt-2 text-danger">{message}</p>}
    </form>
              <hr />
     
              <form>
      <div className="form-group mb-0">
        <label className="d-block">Sessions</label>
        <p className="font-size-sm text-secondary">
          This is a list of devices that have logged into your account. Revoke any sessions that you do not recognize.
        </p>
        <ul className="list-group list-group-sm">
          <li className="list-group-item has-icon">
            <div>
              <h6 className="mb-0">{location ? location.city + " " + location.ip : "Loading..."}</h6>
              <small className="text-muted">
                {location ? `${location.city}, ${location.region}, ${location.country}` : "Fetching location..."}
              </small><br/>
              <small className="text-muted">Your current session seen in  {location ? `${location.city}, ${location.region}, ${location.country}` : "Fetching location..."}</small>
            </div>
           
          </li>
        </ul>
      </div>
    </form>
              <hr />

              <h6>Notification Settings</h6>
              <form>
              <FormGroup>
      {/* Checkbox with default checked */}
      <FormControlLabel
        control={<Checkbox defaultChecked />}
        label="Email each time a vulnerability is found"
      />
      
      {/* Checkbox without checked, marking it as required */}
      <FormControlLabel
        
        control={<Checkbox />}
        label="Email a digest summary of vulnerability"
      />
      
      {/* Disabled checkbox */}
      <FormControlLabel
        
        control={<Checkbox />}
        label="Receive newsletter"
      />
    </FormGroup>


    <div className="form-group mb-0">
      <label className="d-block">SMS Notifications</label>
      <ul className="list-group list-group-sm">
        <li className="list-group-item d-flex align-items-center justify-content-between">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Comments"
          />
        </li>
        <li className="list-group-item d-flex align-items-center justify-content-between">
          <FormControlLabel
            control={<Checkbox />}
            label="Updates From People"
          />
        </li>
        <li className="list-group-item d-flex align-items-center justify-content-between">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Reminders"
          />
        </li>
      </ul>
    </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default ProfileSettings;
