import React, { useEffect } from "react";
import Sidebar from '../SideBar';
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCourseInfo, deleteCourseItem } from "../../../redux/course/courseAction";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const CourseInfo = () => {
    const courseData = useSelector((state) => state.course.courseInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllCourseInfo());
    }, [dispatch]);

    return (
        <div>
            <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
                <div className="app-main">
                    <Sidebar />
                    <div className="col mt-4">
                        <div className="row">
                           

                        <main id="main" class="main">

<section class="section dashboard">
  <div class="row">
                                <div className="col-md-12">
                                    <div className="card recent-sales overflow-auto">
                                        <div className="card-body">
                                            <h5 className="card-title">Courses <span>| Today</span></h5>

                                            <table className="table table-borderless datatable">
                                                <thead>
                                                    <tr>
                                                       
                                                        <th scope="col">Course</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Status</th>
                                                     
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(courseData) && courseData.map((row) => (
                                                        <tr key={row._id}>
                                                           
                                                            <td>{row.courseName}</td>
                                                            <td>{row.courseDescription}</td>
                                                            <td>${row.coursePrice}</td>
                                                            <td>
                                                            <span className="badge bg-success">
                                                                   Active
                                                                </span></td>
                                                           
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                              
            </div>
            </section>
            </main>


</div>
                        </div>
                    </div>
                </div>
            </div>
  
    );
};

export default CourseInfo;
