import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./SideBar";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

const NewsForm = () => {
  const [news, setNews] = useState({ title: "", description: "" });
  const [newsList, setNewsList] = useState([]);
  const [editingNews, setEditingNews] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteNewsId, setDeleteNewsId] = useState(null);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axiosInstance.get("/news/all");
      setNewsList(response.data);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const handleChange = (e) => {
    setNews({ ...news, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingNews) {
        await axiosInstance.put(`/news/${editingNews._id}`, news);
      } else {
        await axiosInstance.post("/news/addnews", news);
      }
      fetchNews();
      setNews({ title: "", description: "", imageUrl: "" });
      setEditingNews(null);
      setOpenEditDialog(false); // Close the dialog after submitting
    } catch (error) {
      console.error("Error saving news:", error);
    }
  };

  const handleEdit = (newsItem) => {
    setNews(newsItem);
    setEditingNews(newsItem);
    setOpenEditDialog(true);
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/news/${deleteNewsId}`);
      fetchNews();
      setOpenDeleteDialog(false); // Close the delete confirmation dialog
    } catch (error) {
      console.error("Error deleting news:", error);
    }
  };

  const handleDeleteDialog = (id) => {
    setDeleteNewsId(id);
    setOpenDeleteDialog(true);
  };

  return (
    <div>
      <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
        <div className="app-main">
          <Sidebar />
          <div className="col mt-4">
            <div className="row">
              <div className="page-title-actions px-3 d-flex">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="">Dashboard</a></li>
                
                    <li className="breadcrumb-item active" aria-current="page">News</li>
                  </ol>
                </nav>
              </div>
              <div className="col-md-12">
                <div className="main-card card d-flex flex-column">
                  <div className="card-body">
                    <h5 className="card-title py-2">Add News</h5>
                    <div style={{ maxWidth: "1200px", margin: "auto", textAlign: "center" }}>
                    
                      <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                        <TextField label="Title" name="title" value={news.title} onChange={handleChange} required />
                        <TextField label="Description" name="description" multiline rows={3} value={news.description} onChange={handleChange} required />
                        <Button 
  type="submit" 
  variant="contained" 
  color="primary" 
  className="btn btn-primary btn-sm" 
  style={{ width: "auto", alignSelf: "start" }} // Adjusting width to be auto and center the button
>
  Add News
</Button>


                      </form>
                      </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                      <div className="main-card card d-flex flex-column">
                      <div className="card-body">

                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Title</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {newsList.map((newsItem) => (
                              <TableRow key={newsItem._id}>
                                <TableCell>{newsItem.title}</TableCell>
                                <TableCell>{newsItem.description}</TableCell>
                               
                                <TableCell>
                                  <Button variant="contained" color="warning" onClick={() => handleEdit(newsItem)} style={{ marginRight: "5px" }}>
                                    Edit
                                  </Button>
                                  <Button variant="contained" color="error" onClick={() => handleDeleteDialog(newsItem._id)}>
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit News Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>{editingNews ? "Edit News" : "Add News"}</DialogTitle>
        <DialogContent>
          <TextField label="Title" name="title" value={news.title} onChange={handleChange} fullWidth required />
          <TextField label="Description" name="description" multiline rows={3} value={news.description} onChange={handleChange} fullWidth required />
          <TextField label="Image URL" name="imageUrl" value={news.imageUrl} onChange={handleChange} fullWidth required />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Are you sure you want to delete this news?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewsForm;
