import React, { useEffect, useState } from "react";
import Sidebar from './SideBar';
import axios from "axios";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import * as echarts from 'echarts';



ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const Student = ({ studentId }) => {

  const [data, setData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const userId = userData ? userData._id : null;
  const username = userData?.userName;
  const dob = userData?.dob 
  ? new Date(userData.dob).toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    }) 
  : 'N/A';

  const email = userData?.email;

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('user');
    console.log('Retrieved from storage:', userDataFromStorage);

    if (userDataFromStorage) {
      try {
        const parsedData = JSON.parse(userDataFromStorage);
        setUserData(parsedData);
      } catch (error) {
        console.error('Failed to parse user data:', error);
      }
    }
  }, []);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axiosInstance.get("/news/all");
      setNewsList(response.data);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const role = userData?.role;

  useEffect(() => {
    const fetchCodes = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        const response = await axiosInstance.get("/api/code", {
          headers: {
            Authorization: "Bearer " + token,
          }
        });
        setData(response.data.codes);
      } catch (error) {
        console.error("Error fetching codes:", error);
      }
    };

    fetchCodes();
  }, []);

  useEffect(() => {
    const fetchPurchasedCourses = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        const response = await axiosInstance.get("/api/purchased-courses", {
          headers: {
            Authorization: "Bearer " + token,
          }
        });

        console.log('Purchased courses response:', response.data); 

        setPurchasedCourses(response.data.courses.flat());
      } catch (error) {
        console.error("Error fetching purchased courses:", error);
      }
    };

    if (userId) {
      fetchPurchasedCourses();
    }
  }, [userId]);

  const getStatus = (output) => {
    if (!output) return "No Output";
    if (/error/i.test(output)) return "Error";
    return "Success";
  };

  const [roomCount, setRoomCount] = useState(null);
  const fetchRoomCount = async (userId) => {
    try {
      const response = await axiosInstance.get(`/room/api/rooms/count?userId=${userId}`);
      setRoomCount(response.data.count);
    } catch (error) {
      console.error('Error fetching room count:', error);
      setRoomCount(null);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchRoomCount(userId);
    }
  }, [userId]);




  const courses = purchasedCourses.filter((item) => item.type === "Course");
  const kits = purchasedCourses.filter((item) => item.type === "Kit");
console.log(courses, kits)

  useEffect(() => {
    if (typeof ApexCharts !== 'undefined' && purchasedCourses.length > 0) {
      const chartOptions = {
        series: [
          { name: 'Codes', data: [data.length, 0] },        // Adding dummy point
          { name: 'Rooms', data: [roomCount || 0, 0] },      // Adding dummy point
          { name: 'Courses', data: [purchasedCourses.length, 0] },  // Adding dummy point
        ],
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 4,
        },
        colors: ['#4154f1', '#2eca6a', '#ff771d'],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.3,
            opacityTo: 0.4,
            stops: [0, 90, 100],
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',  // Ensures the line is smooth and curvy
          width: 2,
        },
        xaxis: {
          categories: [
            `Codes: ${data.length}`,
            `Rooms: ${roomCount || 0}`,
            `Courses: ${purchasedCourses.length}`,
          ],
        },
      };
  
      const chart = new window.ApexCharts(document.querySelector('#reportsChart'), chartOptions);
      chart.render();
  
      return () => chart.destroy();
    }
  }, [data, roomCount, purchasedCourses]);
  
  useEffect(() => {
    const budgetChart = echarts.init(document.getElementById("budgetChart"));
    budgetChart.setOption({
      legend: {
        data: ['Allocated Budget', 'Actual Spending'],
      },
      radar: {
        // The radar indicator categories should match the values you want to display
        indicator: [
          { name: 'Codes', max: data.length }, // Use `data.length` for Codes
          { name: 'Rooms', max: roomCount || 0 }, // Use `roomCount` for Rooms
          { name: 'Courses', max: purchasedCourses.length }, // Use `purchasedCourses.length` for Courses
        ],
      },
      series: [
        {
          name: 'Graph',
          type: 'radar',
          data: [
            {
              value: [data.length, roomCount || 0, purchasedCourses.length], // Use `data.length`, `roomCount`, and `purchasedCourses.length`
              name: 'Rooms Made',
            },
            {
              value: [data.length, roomCount || 0, purchasedCourses.length], // Same values for now; modify based on actual spending if you have it
              name: 'Length',
            },
          ],
        },
      ],


    });

    // Cleanup chart instance on unmount
    return () => {
      budgetChart.dispose();
    };
  }, []);
  

  return (
    <div>
      <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
        <div className="app-main">
          <Sidebar />
      
       
            <main id="main" class="main m-4">

    <section class="section dashboard">
      <div class="row">

    
        <div class="col-lg-8">
          <div class="row">

        
            <div class="col-xxl-4 col-md-6">
              <div class="card info-card sales-card">

                <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                    <li><a class="dropdown-item" href="#">This Month</a></li>
                    <li><a class="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div>

                <div class="card-body">
                  <h5 class="card-title">UserName <span>| For this User</span></h5>

                  <div class="d-flex align-items-center">
                    {/* <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-person"></i> 
                    </div> */}
                    <div class="ps-3">
                   
                      <span class="text-success small pt-1 fw-bold">{role}</span><br/>
                      <span>{username}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-xxl-4 col-md-6">
              <div class="card info-card revenue-card">

                <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                    <li><a class="dropdown-item" href="#">This Month</a></li>
                    <li><a class="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div>

                <div class="card-body">
                  <h5 class="card-title">Details <span>| For this User</span></h5>

                  <div class="d-flex align-items-center">
                   
                    <div class="ps-3">
                  
                    <span className="text-success small pt-1 fw-bold">
   {dob}
</span><br/>

<span className="text-muted small pt-2 ps-1" style={{ wordWrap: 'break-word' }}>
  {email}
</span>


                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-xxl-4 col-xl-12">

              <div class="card info-card customers-card">

                <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                    <li><a class="dropdown-item" href="#">This Month</a></li>
                    <li><a class="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div>

                <div className="card-body">
  <h5 className="card-title">Courses & Kits </h5>

  <div className="d-flex align-items-center">
   
    <div className="ps-3">
     
      <span className="text-danger small fw-bold">{courses?.length || 0} Courses, {kits?.length || 0} Kits</span> <br/>
      <span className="text-muted small">Kits & courses purchased</span>
    </div>
  </div>
</div>

              </div>

            </div>
            <div className="col-12">
      <div className="card">
        <div className="filter">
          <a className="icon" href="#" data-bs-toggle="dropdown">
            <i className="bi bi-three-dots"></i>
          </a>
          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <li className="dropdown-header text-start">
              <h6>Filter</h6>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Today
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                This Month
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                This Year
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <h5 className="card-title">
            Reports <span>/Today</span>
          </h5>
          <div id="reportsChart"></div>
        </div>
      </div>
    </div>
           


    <div className="col-12">
  <div className="card recent-sales overflow-auto">
    <div className="filter">
      <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
        <li className="dropdown-header text-start">
          <h6>Filter</h6>
        </li>
        <li><a className="dropdown-item" href="#">Today</a></li>
        <li><a className="dropdown-item" href="#">This Month</a></li>
        <li><a className="dropdown-item" href="#">This Year</a></li>
      </ul>
    </div>

    <div className="card-body">
      <h5 className="card-title">User Profile <span>| Today</span></h5>

      <table className="table table-borderless datatable">
        <tbody>
          {/* Basic Details */}
          <tr><th scope="col">Name</th><td>{userData?.firstName} {userData?.lastName || "No Username"}</td></tr>
          <tr><th scope="col">Username</th><td>{userData?.userName || "N/A"}</td></tr>
          <tr><th scope="col">Email</th><td>{userData?.email}</td></tr>
          <tr><th scope="col">Role</th><td>{userData?.role || "N/A"}</td></tr>
          <tr><th scope="col">Status</th><td><span className={`badge ${userData?.status === "Pending" ? "bg-warning" : "bg-success"}`}>{userData?.status}</span></td></tr>
          
          {/* Contact Information */}
          <tr><th scope="col">Contact No.</th><td>{userData?.contactNo || "N/A"}</td></tr>
          <tr><th scope="col">Emergency Contact</th><td>{userData?.emergencyContactNo || "N/A"}</td></tr>
          <tr><th scope="col">Guardian Name</th><td>{userData?.guardianName || "N/A"}</td></tr>
          <tr><th scope="col">Guardian Contact</th><td>{userData?.guardianContactNo || "N/A"}</td></tr>
          
          {/* Address Details */}
          <tr><th scope="col">Street</th><td>{userData?.street || "N/A"}</td></tr>
          <tr><th scope="col">City</th><td>{userData?.city || "N/A"}</td></tr>
          <tr><th scope="col">State</th><td>{userData?.state || "N/A"}</td></tr>
          <tr><th scope="col">Country</th><td>{userData?.country || "N/A"}</td></tr>
          <tr><th scope="col">Postal Code</th><td>{userData?.postalCode || "N/A"}</td></tr>

          {/* Education/Enrollment */}
          <tr><th scope="col">Course</th><td>{userData?.course || "N/A"}</td></tr>
          <tr><th scope="col">Batch</th><td>{userData?.batch ? new Date(userData?.batch).toLocaleDateString() : "N/A"}</td></tr>
          
          {/* Additional Info */}
          <tr><th scope="col">Date of Birth</th><td>{userData?.dob ? new Date(userData.dob).toLocaleDateString() : "N/A"}</td></tr>
          <tr><th scope="col">Description</th><td>{userData?.description || "N/A"}</td></tr>
          <tr><th scope="col">Profile Picture</th><td>{userData?.profilePic ? <img src={userData?.profilePic} alt="Profile" width="50" /> : "No Image"}</td></tr>
          <tr><th scope="col">Account Created</th><td>{new Date(userData?.createdAt).toLocaleDateString()}</td></tr>
          <tr><th scope="col">Last Updated</th><td>{new Date(userData?.updatedAt).toLocaleDateString()}</td></tr>

          {/* Identity Verification */}
          <tr>
            <th scope="col">Identity Verifications</th>
            <td>{userData?.identityVerifications.length > 0 ? "Verified" : "Not Verified"}</td>
          </tr>

          {/* Code History */}
          <tr>
            <th scope="col">Code History</th>
            <td>
              {userData?.codeHistory.length > 0 ? (
                <ul>
                  {userData?.codeHistory.map((code, index) => (
                    <li key={index}>{code}</li>
                  ))}
                </ul>
              ) : "No codes created"}
            </td>
          </tr>

          {/* Links */}
          <tr>
            <th scope="col">Links</th>
            <td>
              {userData?.links.length > 0 ? (
                <ul>
                  {userData?.links.map((link, index) => (
                    <li key={index}><a href={link} target="_blank" rel="noopener noreferrer">{link}</a></li>
                  ))}
                </ul>
              ) : "No links available"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


          </div>
        </div>



     
        <div class="col-lg-4">

      
          <div class="card">
            <div class="filter">
              <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <li class="dropdown-header text-start">
                  <h6>Filter</h6>
                </li>

                <li><a class="dropdown-item" href="#">Today</a></li>
                <li><a class="dropdown-item" href="#">This Month</a></li>
                <li><a class="dropdown-item" href="#">This Year</a></li>
              </ul>
            </div>

            <div className="card-body">
  <h5 className="card-title">Recent Activity <span>| Today</span></h5>

  <div className="activity">
    {courses.length > 0 ? (
      courses.map((course, index) => (
        <div key={`course-${index}`} className="activity-item d-flex">
          <div className="activite-label">{Math.floor(Math.random() * 60)} min</div>
          <i className='bi bi-circle-fill activity-badge text-primary align-self-start'></i>
          <div className="activity-content">
            Purchased {courses.length > 1 ? "courses" : "course"}:  <br/>
            <strong> {courses.length}. </strong>
            {courses.length === 1 ? (
              <a href="#" className="fw-bold text-dark"> {course.data.courseName}</a>
            ) : (
              courses.map((c, i) => (
                <span key={i}>
                  {i > 0 && ", "}
                  <a href="#" className="fw-bold text-dark">{c.data.courseName}</a>
                </span>
              ))
            )}
          </div>
        </div>
      ))
    ) : (
      <div className="activity-item d-flex">
        <div className="activite-label">-</div>
        <i className='bi bi-circle-fill activity-badge text-muted align-self-start'></i>
        <div className="activity-content">No courses purchased</div>
      </div>
    )}

    {kits.length > 0 ? (
      kits.map((kit, index) => (
        <div key={`kit-${index}`} className="activity-item d-flex">
          <div className="activite-label">{Math.floor(Math.random() * 24)} hrs</div>
          <i className='bi bi-circle-fill activity-badge text-success align-self-start'></i>
          <div className="activity-content">
            Bought kit: <a href="#" className="fw-bold text-dark">{kit.name}</a>
          </div>
        </div>
      ))
    ) : (
      <div className="activity-item d-flex">
        <div className="activite-label">-</div>
        <i className='bi bi-circle-fill activity-badge text-muted align-self-start'></i>
        <div className="activity-content">No kits bought</div>
      </div>
    )}

    <div className="activity-item d-flex">
      <div className="activite-label">1 day</div>
      <i className='bi bi-circle-fill activity-badge text-warning align-self-start'></i>
      <div className="activity-content">
      {data.length} codes created
      </div>
    </div>

    <div className="activity-item d-flex">
      <div className="activite-label">2 days</div>
      <i className='bi bi-circle-fill activity-badge text-danger align-self-start'></i>
      <div className="activity-content">
       {roomCount} rooms made
      </div>
    </div>
  </div>
</div>

          </div>
        
          <div className="card">
   

      <div className="card-body pb-0">
        <h5 className="card-title">
           Data <span>| This Month</span>
        </h5>

        {/* Chart container */}
        <div id="budgetChart" style={{ minHeight: "300px" }} className="echart"></div>
      </div>
    </div>


          <div class="card">
            <div class="filter">
              <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <li class="dropdown-header text-start">
                  <h6>Filter</h6>
                </li>

                <li><a class="dropdown-item" href="#">Today</a></li>
                <li><a class="dropdown-item" href="#">This Month</a></li>
                <li><a class="dropdown-item" href="#">This Year</a></li>
              </ul>
            </div>

            <div class="card-body pb-0">
              <h5 class="card-title">News &amp; Updates <span>| Today</span></h5>

              <div class="news">
              <div className="news">
                      {newsList.map((newsItem) => (
                        <div className="post-item clearfix" key={newsItem._id}>
                    
                          <div>
                            <h4><a href="#">{newsItem.title}</a></h4>
                            <p>{newsItem.description}</p>
                           
                          </div>
                          </div>
                      ))}
                    </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>

  </main>
          
         
       
        </div>
      </div>
    </div>
  );
};

export default Student;
