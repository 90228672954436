import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Button, TextField, Divider, Avatar, List, ListItem, ListItemText, Modal } from '@mui/material';
import SideBar from "./SideBar";
import axios from "axios";

const MainContent = () => {
  const { id } = useParams(); // Extract the course ID from the URL
  const [course, setCourse] = useState(null);
  const [comments, setComments] = useState([]); // State to hold comments
  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
  const [newComment, setNewComment] = useState("");
  const [name, setName] = useState("");
  const [replyName, setReplyName] = useState(""); // Stores the author's name for a reply
  const [replyContent, setReplyContent] = useState(""); // Stores the reply content
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const [selectedCommentId, setSelectedCommentId] = useState(null); // Tracks the comment ID for the current reply
  const [openReplyModal, setOpenReplyModal] = useState(false); // Controls the reply modal visibility

  
  useEffect(() => {
    const fetchCourse = async () => {
      const response = await axiosInstance.get(`/course/${id}`);
      setCourse(response.data);
    };

    const fetchComments = async () => {
      const response = await axiosInstance.get(`/comments/course/${id}/comments`);
      setComments(response.data);
    };

    fetchCourse();
    fetchComments();
  }, [id]);

  const handleAddComment = async () => {
    if (newComment.trim() === "" || name.trim() === "") return;

    try {
      const response = await axiosInstance.post(`/comments/course/${id}/comments`, {
        author: name, // Send authorName from the frontend
        content: newComment,
      });
      setComments([...comments, response.data]); // Add the new comment to the list
      setNewComment(""); // Clear the comment input
      setName(""); // Clear the name input
      setOpenModal(false); // Close the modal
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleReplyClick = (commentId) => {
    setSelectedCommentId(commentId);
    setOpenReplyModal(true);
  };

  const handleAddReply = async (commentId) => {
    if (replyContent.trim() === "" || replyName.trim() === "") return;
  
    try {
      const response = await axiosInstance.post(
        `/comments/course/${id}/comments/${commentId}/reply`,
        {
          author: replyName,
          content: replyContent,
        }
      );
  
      // Update the comment with the new reply
      const updatedComments = comments.map((comment) =>
        comment._id === commentId
          ? { ...comment, replies: [...comment.replies, response.data.reply] }
          : comment
      );
  
      setComments(updatedComments);
      setReplyName("");
      setReplyContent("");
      setOpenReplyModal(false);
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  };

  return (
    <div>
      <SideBar />
      <div className="main-content">
        <div className="card-grid">
          <Box sx={{ padding: '24px', flexGrow: 1, backgroundColor: '#f8f9fa' }}>
            {/* Heading */}
            <div className="content">
              <div
                className="banner"
                style={{
                  position: "relative",
                  backgroundImage: course?.courseThumbnail ? `url(${course.courseThumbnail})` : "none",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  padding: "80px",
                  borderRadius: "8px",
                  color: "#fff",
                  backgroundColor: course?.courseThumbnail ? "transparent" : "#4285f4",
                }}
              >

                {course?.courseThumbnail && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity (0.5 means 50% transparency)
                      borderRadius: "8px",
                    }}
                  ></div>
                )}

                <h2
                  style={{
                    position: "absolute",
                    bottom: "20px", // Position from the bottom
                    left: "30%",
                    transform: "translateX(-50%)", // Center horizontally
                    zIndex: 1,
                    margin: 0,
                  }}
                >
                  {course ? course.courseName : "Loading course details..."}
                </h2>
              </div>
           
              {/* Course Details */}
              {course && (
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    padding: "24px",
                    marginTop: "24px",
                  }}
                >

<Box
  sx={{
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    marginBottom: '24px',
  }}
>
  <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '16px' }}>
    Assignments
  </Typography>
  <Divider sx={{ marginBottom: '16px' }} />
  {course.assignments.length > 0 ? (
    <List>
      {course.assignments.map((assignment, index) => (
        <ListItem
          key={index}
          sx={{
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            marginBottom: '16px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.08)',
            padding: '16px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '8px' }}>
              {assignment.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: '8px', color: 'gray', lineHeight: '1.6' }}
            >
              {assignment.description
                .split('.')
                .filter(sentence => sentence.trim() !== '')
                .map((sentence, i) => (
                  <div key={i}>{sentence.trim()}.</div>
                ))}
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: '8px', color: 'gray' }}
            >
              <strong>Due Date:</strong> {new Date(assignment.dueDate).toLocaleDateString()}
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: '8px', color: 'gray' }}
            >
              <strong>Resources:</strong>{' '}
              {assignment.resources.length > 0
                ? assignment.resources.map((resource, idx) => (
                    <span
                      key={idx}
                      style={{
                        color: '#1976d2',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginRight: '8px',
                      }}
                    >
                      {resource}
                    </span>
                  ))
                : 'None'}
            </Typography>
          </Box>
        </ListItem>
      ))}
    </List>
  ) : (
    <Typography variant="body2" sx={{ color: 'gray' }}>
      No assignments available.
    </Typography>
  )}
</Box>


                  <Box
  sx={{
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    marginBottom: '24px',
  }}
>
  <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '16px' }}>
    Scheduled Classes
  </Typography>
  <Divider sx={{ marginBottom: '16px' }} />
  {course.scheduledClasses.length > 0 ? (
    <List>
      {course.scheduledClasses.map((assignment, index) => (
        <ListItem
          key={index}
          sx={{
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            marginBottom: '12px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.08)',
            padding: '16px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '8px' }}>
              {assignment.title}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: '8px', color: 'gray' }}>
              <strong>Link: </strong>
              <a href={assignment.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#1976d2' }}>
                {assignment.link}
              </a>
            </Typography>
            <Typography variant="body2" sx={{ color: 'gray' }}>
              <strong>Date: </strong> {new Date(assignment.scheduledTime).toLocaleDateString()} | <strong>Time: </strong>
              {new Date(assignment.scheduledTime).toLocaleTimeString()}
            </Typography>
          </Box>
        </ListItem>
      ))}
    </List>
  ) : (
    <Typography variant="body2" sx={{ color: 'gray' }}>
      No classes scheduled.
    </Typography>
  )}
</Box>

<Box
  sx={{
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    padding: '16px',
  }}
>
  <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '16px' }}>
    Comments
  </Typography>
  <Divider sx={{ marginBottom: '16px' }} />
  <List>
    {comments.map((comment, index) => (
      <ListItem
        key={index}
        sx={{
          alignItems: 'flex-start',
          padding: '12px 0',
        }}
      >
        {/* Avatar + Comment */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Avatar src={comment.authorAvatar} alt={comment.author} sx={{ width: 32, height: 32 }} />
          <Box>
            {/* Comment Content */}
            <Typography variant="body2">
              <strong>{comment.author}</strong> {comment.content}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ display: 'block', marginTop: '4px' }}
            >
              {comment.timestamp}  <Button size="small" sx={{ textTransform: 'none' }} onClick={() => handleReplyClick(comment._id)}>Reply</Button>
            </Typography>
            {/* Replies */}
            <List sx={{ marginLeft: 5, marginTop: 1 }}>
              {comment.replies.map((reply, idx) => (
                <ListItem
                  key={idx}
                  sx={{ alignItems: 'flex-start', padding: '4px 0' }}
                >
                  <Avatar
                    src={reply.authorAvatar}
                    alt={reply.author}
                    sx={{ width: 24, height: 24, marginRight: '8px' }}
                  />
                  <Box>
                    <Typography variant="body2">
                      <strong>{reply.author}</strong> {reply.content}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{ marginTop: '4px', display: 'block' }}
                    >
                      {reply.timestamp}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </ListItem>
    ))}
  </List>

  {/* Add Comment Button */}
  <Button
    variant="outlined"
    sx={{ marginTop: '16px' }}
    onClick={() => setOpenModal(true)}
  >
    Add Comment
  </Button>
</Box>

                </Box>
                
              )}
            </div>
          </Box>
        </div>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: "16px" }}>
            Add a Comment
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ marginBottom: "16px" }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Write your comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            sx={{ marginBottom: "16px" }}
          />
          <Button
            variant="contained"
            sx={{ marginRight: "8px" }}
            onClick={handleAddComment}
          >
            Submit
          </Button>
          <Button variant="outlined" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
        </Box>
      </Modal>
      <Modal open={openReplyModal} onClose={() => setOpenReplyModal(false)}>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: 24,
      p: 4,
    }}
  >
    <Typography variant="h6" sx={{ marginBottom: "16px" }}>
      Add a Reply
    </Typography>
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Your Name"
      value={replyName}
      onChange={(e) => setReplyName(e.target.value)}
      sx={{ marginBottom: "16px" }}
    />
    <TextField
      fullWidth
      multiline
      rows={4}
      variant="outlined"
      placeholder="Write your reply..."
      value={replyContent}
      onChange={(e) => setReplyContent(e.target.value)}
      sx={{ marginBottom: "16px" }}
    />
    <Button
      variant="contained"
      sx={{ marginRight: "8px" }}
      onClick={() => handleAddReply(selectedCommentId)}
    >
      Submit
    </Button>
    <Button variant="outlined" onClick={() => setOpenReplyModal(false)}>
      Cancel
    </Button>
  </Box>
</Modal>

    </div>
  );
};

export default MainContent;
